.towns-tabs > .ant-tabs-nav {
  padding: 0 24px;
  background-color: #ffff;
}

.towns-tabs > .ant-tabs-nav {
  margin-bottom: 0;
}

.no-admins {
  text-align: center;
  margin-top: 150px;
}
.no-admins__text {
  color: #00000040;
}
.add-admin-btn {
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-right: 10px;
}
.add-admin-btn.no-active {
  display: none;
}

.auto-complete-add-admin {
  width: 100%;
}
.auto-complete-add-admin > .ant-select-clear {
  width: 20px;
  height: 20px;
  font-size: 15px;
  margin-top: -8px;
}
.info-category-box-action {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
