/**** Docs Constructor ****/
.docs-constructor-category-label {
    padding: 4px 12px;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    background-color: #00000005;
}
/* .general-form-doc-constructor {
  background-color: #ffff;
  padding-top: 16px;
} */
/**** Docs Constructor Tabs ****/

.tabs-docs-constructor .ant-tabs-tab,
.tabs-docs-constructor .ant-tabs-nav-add {
    border-radius: 2px 2px 0 0 !important;
}
.tabs-docs-constructor .ant-tabs-nav-wrap {
    background-color: #ffff;
    padding: 0 24px;
    height: 40px;
}
.step-constructor {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 24px 0;
}

.step-constructor_buttons-submit {
    display: flex;
    gap: 24px;
    background-color: white;
    padding: 24px 0;
    margin-top: 24px;
    justify-content: center;
}
.step-constructor__menu {
    width: 250px;
    background-color: transparent;
    border: none !important;
}
.step-constructor__menu > li > div {
    background-color: #ffff;
}

.step-constructor__content .ant-menu.ant-menu-sub.ant-menu-inline {
    background-color: transparent;
    display: flex;
    flex-wrap: wrap;
}
.step-constructor__content .ant-menu.ant-menu-sub.ant-menu-inline.ant-menu-hidden {
    display: none;
}
.step-constructor__content .ant-menu-item.ant-menu-item-only-child {
    padding: 0 !important;
    text-align: center;
    cursor: default;
    width: calc(100%) !important;
    height: 40px;
}
.step-constructor__content {
    display: flex;
    gap: 24px;
}
.step-constructor__item {
    display: flex;
    gap: 10px;
    width: 225px;
    align-items: center;
    border-radius: 8px;
    padding: 10px 12px;
    text-align: center;
    background-color: #ffff;
    height: 50px;
}
.step-constructor__item:hover {
    background-color: rgba(0, 0, 0, 0.06);
}
.step-constructor__dnd {
    width: 100%;
}
.step-constructor__open-menu {
    display: none;
    gap: 8px;
    justify-content: space-between;
    width: 448px;
    margin: 4px;
}
.dragg-area {
    min-height: calc(100vh - 352px);
    border: 2px dashed #d9d9d9;
    border-radius: 8px;
    background-color: rgba(0, 0, 0, 0.02);
}

.upload-area {
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    min-height: 170px;
    border: 2px dashed #d9d9d9;
    border-radius: 8px;
    background-color: rgba(0, 0, 0, 0.02);
}
.dnd-card {
    position: relative;
    background-color: #ffff;
    border-radius: 8px;
    padding: 16px 56px 16px 48px;
    margin-bottom: 8px;
}
.dnd-drop-icon {
    font-size: 16px;
    cursor: move;
    position: absolute;
    top: 12px;
    left: 12px;
}
.dnd-delete-icon {
    font-size: 16px;
    color: #ff0000;
    position: absolute;
    top: 12px;
    right: 12px;
}
.dnd-card__card-title {
    margin: 0;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.5;
}
.dnd-card__input-title {
    margin: 16px 0px 4px 0px;
}
/* set transition to none when type="editable-card" */
.ant-tabs-editable > .ant-tabs-nav .ant-tabs-tab {
    transition: none;
}

.modal-delete > .ant-modal-content {
    padding: 28px 40px;
}
.title-photo-constructor span {
    font-style: italic;
    color: #00000040;
}
.general-form-buttons-submit {
    display: flex;
    justify-content: center;
    background-color: #ffff;
    margin: 0px 24px;
    padding: 12px 0px 24px 0px;
}
.content-conteiner.constructor-conteiner {
    margin: 0 24px;
    padding: 12px 0px;
}
.constructor-form {
    padding-bottom: 24px;
    max-width: calc(100vw - 237px);
}
.constructor-autofill {
    position: relative;
}
.constructor-autofill__item > .ant-form-item {
    margin-bottom: 12px;
}
.constructor-block-price {
    display: flex;
    gap: 16px;
}
.constructor-block-price__item {
    width: calc(100% / 2 - 16px);
}

.step-constructor__item {
    display: flex;
    gap: 10px;
    width: 225px;
    align-items: center;
    border-radius: 8px;
    padding: 10px 12px;
    text-align: center;
    background-color: #ffff;
    height: 50px;
}

