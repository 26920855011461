@import url("/src/pages/LoginPage/LoginPage.css");
@import url("/src/pages/AdminHomePage/AdminHomePage.css");
@import url("/src/components/AdminHeader/AdminHeader.css");
@import url("/src/components/States/States.css");
@import url("/src/components/Towns/Towns.css");
@import url("/src/components/EditPlace/EditFormCityCouncilSettings/EditFormCityCouncilSettings.css");
@import url("/src/components/HowToUse/HowToUse.css");

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
