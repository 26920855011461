.content {
  background-color: #f8f9fc;
}
.content-header {
  padding: 16px 24px;
  background-color: #ffff;
}
.content-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 28px;
  font-weight: 500;
  margin: 16px 0 0 0;
  min-height: 32px;
}
.content-title__list {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  gap: 12px;
  align-items: baseline;
}
.content-title__item {
  color: #00000073;
  font-size: 14px;
  line-height: 1.58;
}
.content-title__item:first-child {
  font-size: 20px;
  font-weight: 500;
  color: #000000d9;
  line-height: 1.4;
}
.content-conteiner {
  margin: 24px;
  padding: 12px 24px;
  /* min-height: 100vh; */
  background-color: #ffff;
}

.content-conteiner.preview {
  margin: 24px 0;
  min-height: 100%;
}

.content-conteiner.state {
  max-height: calc(100vh - 225px);
  overflow-y: auto;
}
.list-header .ant-list-header {
  padding-bottom: 0;
}

.content-conteiner-list__item {
  height: 46px;

  transition: all 300ms;
}
.content-conteiner-list__item:hover {
  background-color: #fafafa;
}
.content-conteiner-list__item a {
  display: block;
  width: 100%;
  padding: 8px 0;
}

.ant-list-item-meta-title {
  margin: 0 !important;
}
.community-table__link {
  display: block;
  color: #000000e0;
  font-weight: 400;
  width: 100%;
  padding-top: 12px;
  padding-bottom: 12px;
}
.community-table__item {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.community-table__status {
  display: flex;
  align-items: center;
}
.community-table__status-active {
  display: flex;
  align-items: center;
  font-weight: 500;
}
.community-table__status-circle {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #00000026;
  margin-right: 10px;
}
.community-table__status-circle-active {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #16ad85;
  margin-right: 10px;
}
