.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 48px;
  color: #ffffff;
  line-height: normal;
  background-color: #152235;
  font-size: 12px;
}
.headerLogo {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.btn-logout {
  display: flex;
  align-items: center;
  gap: 9px;
  cursor: pointer;
}
.notification {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: 24px;
  width: 48px;
  height: 100%;
  cursor: pointer;
}
.count-notification {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  top: 2px;
  right: 1px;
  background-color: #d72653;
}
